exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-book-js": () => import("./../../../src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-divulgacion-js": () => import("./../../../src/pages/divulgacion.js" /* webpackChunkName: "component---src-pages-divulgacion-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investigacion-js": () => import("./../../../src/pages/investigacion.js" /* webpackChunkName: "component---src-pages-investigacion-js" */),
  "component---src-pages-libros-js": () => import("./../../../src/pages/libros.js" /* webpackChunkName: "component---src-pages-libros-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

